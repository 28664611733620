// HOME
export const HOME_SERVICE_DATA = {
  sectionTitle: {
    title: "How Prompt Breeders Helps You",
    text: "SERVICES"
  },
  posts: [
    {
      extraClassName: "bg-2",
      icon: "piloz-human-resources",
      title: "Consulting",
      text: "Everything is moving very quickly in the field of generative AI.\n Should it be adopted immediately? At what intensity? How to manage the risks? \nWe help our clients seize the opportunities and potential of these technologies to improve their operations and develop new businesses.",
      button: {
        label: "Learn More",
        link: "/conseil"
      }
    },
    {
      extraClassName: "bg-4",
      icon: "piloz-gear",
      title: "Solutions",
      text: "Our suite of solutions leverages the power of the latest generative AI to drive significant business impact:\n - Mairlin, the Recruiter's Co-pilot\n - Breebs (Chat wit Knowledge, and GPTs development)\n - InspectMate (GenAI-powered App for field inspection)\n - Luzean (Prompts, Chains, and Agents @Scale)",

      button: {
        label: "Learn More",
        link: "/solutions"
      }
    },
    {
      extraClassName: "",
      icon: "piloz-user",
      title: "Training",
      text: "Prompt Breeders designs and delivers customized training - tailored to your context and challenges. \n The goal is always to enhance the skills of your teams and their ability to implement generative AI in their daily operations, in a practical and secure manner.",
      button: {
        label: "Learn More",
        link: "/formation"
      }
    }

    // {
    //   extraClassName: "bg-3",
    //   icon: "piloz-writing",
    //   title: "QAWhat",
      
    //   text: "QAWhat est la plateforme de Chat développée par Prompt Breeders.\nElle permet de tirer le meilleur des derniers modèles de langage, en apportant des solutions concrètes à leurs limites actuelles: privacy, spécialisation, personnalisation et intégration.",
    //   button: {
    //     label: "En savoir plus",
    //     link: "/qawhat"
    //   }
    // },
    // {
    //   extraClassName: "bg-4",
    //   icon: "piloz-gear",
    //   title: "LUZEAN",
    //   text: "Luzean est la plateforme d’hébergement de prompts et agents IA proposée par Prompt Breeders.\nElle est conçue pour permettre l’intégration des technologies IA Génératives dans vos process métiers et votre architecture applicative, avec un haut niveau de qualité et de sécurité",
    //   button: {
    //     label: "En savoir plus",
    //     link: "/luzean"
    //   }
    // }
  ]
};


export const HOME_FORMATION_DATA = {
  sectionTitle: {
    text: "TRAININGS",
    title: "Understand and Use \n Generative AI"
  },
  paragraph:
    "Prompt Breeders offers customized training - tailored to your context and challenges.",
    options: [
      {
        label: "Discover the foundations of AI, explore generative technologies, and understand the impact of ChatGPT."
      },
      {
        label: "Learn to use generative AI in your daily operations, by understanding and mastering the risks."
      },
      {
        label: "Explore the latest advancements, from autonomous agents to AI as a Service."
      },
      {
        label: "Anticipate and prepare for what's next by familiarizing yourself with emerging trends."
      }
    ],
  button: {
    link: "/formation",
    label: "Training Offer"
  }
};




export const HOME_CONSEIL_DATA = {
  sectionTitle: {
    title: "Consulting, Design, and Integration",
    text: "CONSULTING"
  },
  paragraph:
    "Everything is moving very quickly in the field of AI and language models.\nWe help our clients seize the opportunities and potential of these technologies, while managing the risks.",
    options: [
      {
        label: "Strategic Consulting, Risks, Best Practices, Ideation."
      },
      {
        label: "Prompt engineering & Agent design"
      },
      {
        label: "AI Solution Design & Hosting"
      }
    ],
  button: {
    link: "/conseil",
    label: "Our Services"
  }
};



import mairlin1 from "@images/solutions/mairlin_1.webp";
import mairlin2 from "@images/solutions/mairlin_2.webp";
import solutions1 from "@images/solutions/solutions-1.webp";
import solutions2 from "@images/solutions/solutions-2.webp";
import solutions3 from "@images/solutions/solutions-3.webp";
import solutions4 from "@images/solutions/solutions-4.webp";
import solutions5 from "@images/solutions/solutions-5.webp";
import solutions6 from "@images/solutions/solutions-6.webp";
import solutions7 from "@images/solutions/solutions-7.webp";

export const HOME_SOLUTIONS_DATA = {
  sectionTitle: {
    title: "Our solutions for maximizing the impact of generative AI.",
    text: "SOLUTIONS"
  },
  paragraph:
    "We design innovative solutions that overcome the traditional limitations associated with ChatGPT: specialization, security, customization, multi-model agents, non-conversational industrial applications... \nOur technology stack integrates the latest advancements in the field and best architectural practices: Serverless, Vectorial DB, Langchain, APIs, etc. \nSome Prompt Breeders solutions include:",
  options: [
    {
      label: "Mairlin, the ultimate personal assistant for recruiters"
    },
    {
      label: "Breebs, for AI-driven knowledge access"
    },
    {
      label: "InspectMate, Voice & Video GenAI assistant for field inspections"
    },
    {
      label: "Luzean, our serverless hosting and orchestration platform for AI chains and agents, for direct integration into your processes."
    },
    {
      label: "GPTs prompting and development"
    }
  ],
  posts: [
    {
      image: mairlin1
    },
    {
      image: mairlin2
    },
    {
      image: solutions1
    },
    {
      image: solutions2
    },
    {
      image: solutions3
    },
    {
      image: solutions4
    },
    {
      image: solutions5
    },
    {
      image: solutions6
    },
    {
      image: solutions7
    },
    {
      image: mairlin1
    },
    {
      image: mairlin2
    },
    {
      image: solutions1
    },
    {
      image: solutions2
    },
    {
      image: solutions3
    },
    {
      image: solutions4
    },
    {
      image: solutions5
    },
    {
      image: solutions6
    },
    {
      image: solutions7
    }
  ],
  button: {
    link: "/solutions",
    label: "Solutions"
  }
};







import qawhat1 from "@images/qawhat/qawhat-1.png";
import qawhat2 from "@images/qawhat/qawhat-2.png";
import qawhat3 from "@images/qawhat/qawhat-3.png";
import qawhat4 from "@images/qawhat/qawhat-4.png";
import qawhat5 from "@images/qawhat/qawhat-5.png";

export const HOME_QAWHAT_DATA = {
  sectionTitle: {
    title: "QAWhat, notre plateforme de Chat spécialisée, personnalisée, et boostée",
    text: "QAWhat"
  },
  paragraph:
    "QAWhat est la plateforme de Chat développée par Prompt Breeders.\nElle permet de tirer le meilleur des derniers modèles de langage (dont ChatGPT), tout en apportant des solutions concrètes à leurs limites actuelles : privacy, spécialisation, personnalisation, intégration."
  ,
  posts: [
    {
      image: qawhat1
    },
    {
      image: qawhat2
    },
    {
      image: qawhat3
    },
    {
      image: qawhat4
    },
    {
      image: qawhat5
    },
    {
      image: qawhat1
    },
    {
      image: qawhat2
    },
    {
      image: qawhat3
    },
    {
      image: qawhat4
    },
    {
      image: qawhat5
    }
  ],
  button: {
    link: "/qawhat",
    label: "QAWhat"
  }
};


import homeLuzeanImg from "@images/luzean/luzean-cloud.webp";

export const HOME_LUZEAN_DATA = {
  sectionTitle: {
    title: "Luzean, notre plateforme d’hébergement de prompts, chaînes et agents.",
    text: "LUZEAN"
  },
  image: homeLuzeanImg,
  paragraph:
    "Conçue pour faciliter l'intégration des technologies IA génératives dans les processus métiers, Luzean peut être utilisée directement en tant que service intégré dans vos applications ou intégrée dans vos processus métiers. ",
  options: [
    {
      label: "Hosted Generative AI Services"
    },
    {
      label: "Prévisibilité, Qualité, Reproductibilité"
    },
    {
      label: "Prompt Chains et Agents"
    },
    {
      label: "Data Privacy"
    }
  ],
  button: { link: "/luzean", label: "LUZEAN" }
};
//////////////////////////////////////////////////
// FORMATION

export const FORMATION_COMPRENDRE_DATA = {
  sectionTitle: {
    text: "PROMPT BREEDERS - TRAININGS",
    title: "Understand Generative AI"
  },
  paragraph:
    "From the history of AI to the state of the art, through an in-depth exploration of ChatGPT, our training guides you to understand these revolutionary technologies. Discover how ChatGPT is transforming the AI landscape and explore the fundamental principles that make it so powerful.",
  options: [
    {
      label: "Understanding AI simply: History, approaches, and state of the art"
    },
    {
      label: "Overview of Generative Technologies in AI"
    },
    {
      label: "ChatGPT, the game-changing generative AI: understanding its impact on the AI landscape"
    },
    {
      label: "From 'give me the next word' to 'emergent properties': Understanding how ChatGPT works"
    }
  ]
};


import formationUtiliserImg from "@images/formation-utiliser.png";

export const FORMATION_UTILISER_DATA = {
  sectionTitle: {
    title: "ChatGPT & Co: What For and How?",
    text: "PROMPT BREEDERS - TRAININGS"
  },
  image: formationUtiliserImg,
  paragraph:
    "Discover how to use these technologies daily to improve productivity, facilitate your work, and speed up laborious tasks. From exploring tools and practical cases to awareness of risks and best practices, you'll be equipped to navigate this constantly evolving field.",
  options: [
    {
      label: "Using generative AI daily: tools, practical cases, and limitations"
    },
    {
      label: "The art of the prompt and context: write effectively for more efficiency and to avoid undesirable results"
    },
    {
      label: "Awareness of risks and best practices: data privacy, information freshness, hallucination, variability, and bias"
    },
    {
      label: "Beyond ChatGPT: exploring models, tools, and multimodality for daily use"
    }
  ]
};

export const FORMATION_ANTICIPER_DATA = {
  sectionTitle: {
    title: "Stay at the Forefront of Rapid Advances in Generative AI",
    text: "PROMPT BREEDERS - TRAININGS"
  },
  paragraph:
    "Discover the very latest innovations shaping this constantly evolving field. Anticipate their impact on your activities.",
    options: [
      {
        label: "Prompt chains, tools, multi-modality, advanced and autonomous agents: towards Artificial General Intelligence?"
      },
      {
        label: "Acceleration and composition frameworks: focus on langchain and its practical use"
      },
      {
        label: "AI as a Service: a new paradigm in architecture, development, and hosting"
      }
    ]
};


//////////////////////////////////////////////////
// CONSEIL

export const CONSEIL_STRAT_DATA = {
  sectionTitle: {
    text: "PROMPT BREEDERS - CONSULTING",
    title: "Consulting: Strategy, Use Cases, and Risks"
  },
  paragraph:
    "Prompt Breeders accompanies you in defining the usage framework for Generative AIs in your activities, based on immediate risks and opportunities (charter, best practices, training plan, etc.).\n\nWe also explore with you the strategic opportunities for optimizing and transforming your operational model, taking into account your activity and priorities.\nSome of these opportunities may be obvious and easily actionable (such as improving individual productivity, customer service, or internal chatbots).\nOthers require imagination based on the emerging properties of language models.",
  options: [
  ]
};


import conseilPromptImg from "@images/conseil/conseil-prompt.webp";

export const CONSEIL_PROMPT_DATA = {
  sectionTitle: {
    title: "Art of Prompt",
    text: "PROMPT BREEDERS - CONSULTING"
  },
  image: conseilPromptImg,
  paragraph:
    "The Prompt is the interface that allows you to control language models, and it is also the cornerstone of more advanced generative AI solutions. \nPrompt Breeders brings you its expertise in the art of prompt crafting.",
    options: [
      {
      label: "Expertise in prompt design: quality, reproducibility, reduction of flexibility"
      },
      {
      label: "Prompt chain design: robustness, continuous improvement, etc."
      },
      {
      label: "Agent and GPTs design: integration of public or private knowledge sources, leveraging emerging capabilities, autonomous agents, etc."
      }
      ]
};

export const CONSEIL_SOLUTION_DATA = {
  sectionTitle: {
    title: "Solution Design & Prompt Hosting",
    text: "PROMPT BREEDERS - CONSULTING"
  },
  paragraph:
    "Prompt Breeders assists you in effectively integrating generative AI solutions into your operational model and architecture",
    options: [
    {
    label: "Design and Integration of AI-APIs into your processes and systems"
    },
    {
      label: "Envision and design unique transformative solutions combining GenAI with traditional SaaS solutions, such as Mairlin"
    },
    {
    label: "Design, hosting, and monitoring of GPTs or dedicated Breebs"
    },
    {
    label: "With Luzean, hosting and monitoring of your prompts, chains, and agents (availability, quality, continuous monitoring, etc.)"
    },
    {
    label: "Design of embedded GenAI Apps and solutions, based on latest Multimodals Models"
    }
    ]
};


//////////////////////////////////////////////////
// QAWHAT

export const QAWHAT_PRIVACY_DATA = {
  sectionTitle: {
    text: "PROMPT BREEDERS - QAWHAT",
    title: "Privacy"
  },
  paragraph:
    "Avez-vous une idée du nombre de données confidentielles (contrats, code source, plans stratégiques…) qui sont transmises par vos salariés à ChatGPT, et utilisées sans restriction pour son entrainement?\nContrairement aux solutions grand public d’OpenAI (dont ChatGPT) les prompts communiqués à QAWhat ne sont pas réutilisés pour l’entrainement de nos modèles ou ceux de nos partenaires",
  options: [
  ]
};


import qawhatPersoImg from "@images/resources/cta-2-moc-1.png";

export const QAWHAT_PERSO_DATA = {
  sectionTitle: {
    title: "Spécialisation et Personnalisation",
    text: "PROMPT BREEDERS - QAWHAT"
  },
  image: qawhatPersoImg,
  paragraph:
    "QAWhat intègre les dernières technologies de prompt engineering pour vous permettre de personnaliser, spécialiser et booster son comportement",
  options: [
    {
      label: "Spécialisation sur vos ressources techniques ou documentaires"
    },
    {
      label: "Accès aux données temps réel"
    },
    {
      label: "Connexion aux API externes ou propriétaires"
    },
    {
      label: "Intégration d'agents autonomes"
    }
  ],
  posts: [
    {
      image: qawhat1
    },
    {
      image: qawhat2
    },
    {
      image: qawhat3
    },
    {
      image: qawhat4
    },
    {
      image: qawhat5
    },
    {
      image: qawhat1
    },
    {
      image: qawhat2
    },
    {
      image: qawhat3
    },
    {
      image: qawhat4
    },
    {
      image: qawhat5
    },
    {
      image: qawhat1
    },
    {
      image: qawhat2
    },
    {
      image: qawhat3
    },
    {
      image: qawhat4
    },
    {
      image: qawhat5
    }
  ]
};

export const QAWHAT_INTEGRATION_DATA = {
  sectionTitle: {
    title: "Intégration",
    text: "PROMPT BREEDERS - QAWHAT"
  },
  paragraph:
    "Construit autour d’une solution de chatbot de référence, QAWhat peut être facilement intégré dans vos chatbot existants.\nQAWhat est LA solution pour en booster l’expérience et l’adoption.",
    options: [
    ]
};





//////////////////////////////////////////////////
// SOLUTIONS



import solutionsPluginsImg from "@images/solutions/solutions-3.webp";

export const SOLUTIONS_BREEBS_DATA = {
  sectionTitle: {
    title: "Chat with knowledge, Chat with Breebs",
    text: "PROMPT BREEDERS - BREEBS"
  },
  image: solutionsPluginsImg,
  paragraph:
    "Breebs revolutionizes knowledge access by transforming complex documents into interactive AI-digestible capsules. \nBreebs allows users to create and interact with these knowledge, enabling deep, precise conversations with AI. It democratizes information, making it accessible and engaging for everyone, from experts in their fields to curious learners. \n\nWith Breebs, every query becomes a journey into comprehensive, accurate knowledge."
};

export const SOLUTIONS_INSPECTMATE_DATA = {
  sectionTitle: {
    text: "PROMPT BREEDERS - INSPECTMATE",
    title: "InspectMate"
  },
  paragraph:
    "InspectMate is transforming the landscape for field inspection professionals, covering a broad spectrum of activities such as real estate condition assessments, construction site reviews, and various diagnostics. \nThis innovative tool eliminates the need for traditional notepads, checkboxes, and dictaphones. \nWith its advanced capabilities, InspectMate not only sees and listens but also analyzes environment, access Breebs for specialized documentation and generates comprehensive reports. \n\nThis breakthrough is a testament to our deep expertise in mobile development, combined with cutting-edge prompt techniques on the latest multimodal models. \nVersatile and adaptable, InspectMate is designed to cater to a wide range of industries, offering customizable solutions for any reporting formats",
  options: [
  ]
};

export const SOLUTIONS_MAIRLIN_DATA = {
  sectionTitle: {
    text: "PROMPT BREEDERS - MAIRLIN",
    title: "Mairlin, the Recruiter's Copilot"
  },
  paragraph:
    "Mairlin is transforming recruitment with AI-powered assistance for recruiters, HR managers, and anyone involved in hiring. \n\nMairlin saves time by sorting, writing, translating, summarizing, and organizing job descriptions and resumes. Mairlin identifies top candidates and provides detailed summaries, personalized messages, and custom interview guides. \nUsing advanced AI, Mairlin reduces biases, promotes diversity, and supports multiple languages for international hires. It ensures data security by hosting information in Europe and not using it to train AI models.\n\n",
  options: [
  ]
};


export const SOLUTIONS_LUZEAN_DATA = {
  sectionTitle: {
    text: "PROMPT BREEDERS - LUZEAN",
    title: "Hosted Generative AI Services"
  },
  paragraph:
    "Luzean is our hosting platform for agents and prompt chains, combining various models. \nIt is designed to enable the integration of Generative AI technologies into your business processes and application architecture, ensuring Predictability, Quality, and Reproducibility. \n\nLuzean leverages the emerging capabilities of the latest models (few-shot prompting, instruction following, tool selection, etc.) and benefits from integration into your environment (DB exploration, API triggering, QA with source, etc.). \n\nYou can see Luzean in action in an example of autonomous generation of advice and recommendation blogs, for instance :",
  options: [
  ]
};



export const SOLUTIONS_QAWHAT_DATA = {
  sectionTitle: {
    title: "QAWhat - Boosted and Secure ChatBot",
    text: "PROMPT BREEDERS - QAWHAT"
  },
  paragraph:
    "QAWhat is the Chat platform developed by Prompt Breeders. It allows you to make the most of the latest language models (including ChatGPT), while providing concrete solutions to their current risks and limitations: privacy, specialization, integration. \n\nQAWhat incorporates the latest prompt engineering technologies to allow you to customize, specialize, and boost its behavior.",
  options: [
    {
      label: "Specialization on your technical or documentary resources"
    },
    {
      label: "Real-time data access"
    },
    {
      label: "Connection to external or proprietary APIs"
    },
    {
      label: "Integration of autonomous agents"
    }
  ],
  posts: [
    {
      image: qawhat1
    },
    {
      image: qawhat2
    },
    {
      image: qawhat3
    },
    {
      image: qawhat4
    },
    {
      image: qawhat5
    },
    {
      image: qawhat1
    },
    {
      image: qawhat2
    },
    {
      image: qawhat3
    },
    {
      image: qawhat4
    },
    {
      image: qawhat5
    },
    {
      image: qawhat1
    },
    {
      image: qawhat2
    },
    {
      image: qawhat3
    },
    {
      image: qawhat4
    },
    {
      image: qawhat5
    }
  ]
};

export const SOLUTIONS_STACK_DATA = {
  sectionTitle: {
    text: "PROMPT BREEDERS - STACK"
  },

};


export const SOLUTIONS_PICTURES_DATA = {
  sectionTitle: {
    text: "PROMPT BREEDERS - SOLUTIONS"
  },
  posts: [
    {
      image: solutions1
    },
    {
      image: solutions2
    },
    {
      image: solutions3
    },
    {
      image: solutions4
    },
    {
      image: solutions5
    },
    {
      image: solutions6
    },
    {
      image: solutions7
    },
    {
      image: solutions1
    },
    {
      image: solutions2
    },
    {
      image: solutions3
    },
    {
      image: solutions4
    },
    {
      image: solutions5
    },
    {
      image: solutions6
    },
    {
      image: solutions7
    }
  ]
};

//////////////////////////////////////////////////
// LUZEAN

export const LUZEAN_HOSTED_DATA = {
  sectionTitle: {
    text: "PROMPT BREEDERS - LUZEAN",
    title: "Hosted Generative AI Services"
  },
  paragraph:
    "Luzean, c'est l'endroit où Prompt Breeders élève et prend soin de vos prompts et agents! \nIls sont exécutés et controlés, à la demande ou régulièrement. \nLes résultats, après qualification, sont exposés sous forme d’API directement intégrable dans vos process.",
  options: [
  ]
};

import luzeanQualiteImg from "@images/luzean/luzean-sankey.webp";
export const LUZEAN_QUALITE_DATA = {
  sectionTitle: {
    title: "Prévisibilité, Qualité,  Reproductibilité",
    text: "PROMPT BREEDERS - LUZEAN"
  },
  image: luzeanQualiteImg,
  paragraph:
    "Chaque exécution fait l’objet d’un processus de criblage et de validation automatique.\nIn fine, seul le meilleur résultat est exposé, permettant de limiter au maximum les problèmes de qualité et de reproductibilité (hallucinations, formats, biais, ..)",
    options: [
    ]
};


export const LUZEAN_CHAIN_DATA = {
  sectionTitle: {
    title: "Prompt Chains et Agents",
    text: "PROMPT BREEDERS - LUZEAN"
  },

  paragraph:
    "Luzean permet de concevoir des chaines de prompts et agents combinant différents modèles.\nLuzean exploite ainsi les capacités émergentes des derniers modèles (few-shot prompting, instruction following, tool selection, …) et tire profit de l’intégration à votre environnement (DB exploration, API triggering, QA with source, …)",
  options: [
  ]
};



export const LUZEAN_HITL_DATA = {
  sectionTitle: {
    text: "PROMPT BREEDERS - LUZEAN",
    title: "Human In The Loop "
  },
  paragraph:
    "En bout de chaîne, pour des process demandant un très haut niveau de qualité, un process de validation humaine (revue/sélection) est intégré.",
  options: [
  ]
};


import luzeanPrivacyImg from "@images/luzean/luzean-privacy.webp";

export const LUZEAN_PRIVACY_DATA = {
  sectionTitle: {
    title: "Data Privacy",
    text: "PROMPT BREEDERS - LUZEAN"
  },
  image: luzeanPrivacyImg,
  paragraph:
    "Les agents et prompts hébergés dans Luzean ne sont pas soumis à la Politique standard d’utilisation des données d’OpenAI.\nIls ne sont pas réutilisés pour l’entrainement des futures versions des modèles GPT.\nEt surtout Luzean et les données de nos clients (prompts, inputs, résultats) sont hébergés en Europe!",
  options: [
  ]
};

//////////////////////////////////////////////////
// NOT USED

import videoBG from "@images/resources/video-bg-1-1.jpg";

export const VIDEO_DATA = {
  title: "Watch Our Video",
  image: videoBG,
  videoID: "Kl5B6MBAntI"
};


export const CALL_TO_ACTION_DATA = {
  sectionTitle: {
    text: "Our App Feature Lists",
    title: "Powerful App for \n Your Mobile"
  },
  paragraph:
    "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla pariatur. Lorem Ipsum is simply dummy text of the printing laborum  perspiciatis unde.",
  posts: [
    {
      title: "Responsive \n Design",
      extraClassName: " ",
      icon: "piloz-devices"
    },
    {
      title: "Online \n Marketing",
      icon: "piloz-networking",
      extraClassName: "color-2"
    }
  ],
  button: {
    link: "#",
    label: "Discover More"
  }
};



import appFeature1 from "@images/resources/cta-2-moc-1.png";
export const APP_FEATURE_DATA = {
  sectionTitle: {
    title: "Discover Tools for\nYour Customers",
    text: "Our App Feature Lists"
  },
  image: appFeature1,
  paragraph:
    "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum lorem ipsum is simply free.",
  options: [
    {
      label: "Refresing to get such a personal touch."
    },
    {
      label: "Duis aute irure dolor in reprehenderit in voluptate."
    },
    {
      label: "Velit esse cillum dolore eu fugiat nulla pariatur."
    }
  ],
  button: { link: "#", label: "Discover More" }
};



export const PRICING_DATA = {
  sectionTitle: {
    title: "Our Pricing Plans",
    text: "Choose Our Pricing"
  },
  monthly: [
    {
      count: "01",
      amount: "$20.00",
      name: "Basic Pack",
      extraClassName: "color-1",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    },
    {
      count: "02",
      amount: "$30.00",
      name: "Basic Pack",
      extraClassName: "color-2",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    },
    {
      count: "03",
      amount: "$60.00",
      name: "Basic Pack",
      extraClassName: "color-3",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    }
  ],
  yearly: [
    {
      count: "01",
      amount: "$40.00",
      name: "Premium Pack",
      extraClassName: "color-1",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    },
    {
      count: "02",
      amount: "$80.00",
      name: "Premium Pack",
      extraClassName: "color-2",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    },
    {
      count: "03",
      amount: "$100.00",
      name: "Premium Pack",
      extraClassName: "color-3",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    }
  ]
};

import testimonial1 from "@images/testimonials/testimonials-1-img-1.png";
import testimonial2 from "@images/testimonials/testimonials-1-img-2.png";
import testimonialShape from "@images/testimonials/testimonials-1-shape-1.png";
import testimonialShape2 from "@images/testimonials/testimonials-1-shape-2.png";

export const TESTIMONIALS_DATA = {
  sectionTitle: {
    text: "Our Testimonials",
    title: "What They Says"
  },
  posts: [
    {
      extraClassName: " ",
      shape: testimonialShape,
      image: testimonial1,
      title: "Isabel Griffith",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: "color-2",
      image: testimonial2,
      shape: testimonialShape2,
      title: "Wesley Dixon",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: " ",
      image: testimonial1,
      shape: testimonialShape,
      title: "Isabel Griffith",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: "color-2",
      image: testimonial2,
      shape: testimonialShape2,
      title: "Wesley Dixon",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: " ",
      image: testimonial1,
      shape: testimonialShape,
      title: "Isabel Griffith",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: "color-2",
      image: testimonial2,
      shape: testimonialShape2,
      title: "Wesley Dixon",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    }
  ]
};




import appshot1 from "@images/app-shots/app-shot-n-1-1.png";
import appshot2 from "@images/app-shots/app-shot-n-1-2.png";
import appshot3 from "@images/app-shots/app-shot-n-1-3.png";
import appshot4 from "@images/app-shots/app-shot-n-1-4.png";
import appshot5 from "@images/app-shots/app-shot-n-1-5.png";

export const APP_SHOT_DATA = {
  sectionTitle: {
    title: "App Screenshots",
    text: "Check Before Choosing"
  },
  posts: [
    {
      image: appshot1
    },
    {
      image: appshot2
    },
    {
      image: appshot3
    },
    {
      image: appshot4
    },
    {
      image: appshot5
    },
    {
      image: appshot1
    },
    {
      image: appshot2
    },
    {
      image: appshot3
    },
    {
      image: appshot4
    },
    {
      image: appshot5
    },
    {
      image: appshot1
    },
    {
      image: appshot2
    },
    {
      image: appshot3
    },
    {
      image: appshot4
    },
    {
      image: appshot5
    }
  ]
};

export const FAQ_DATA = {
  sectionTitle: {
    text: "Frequently Asked Questions",
    title: "Have Any Question?"
  },
  posts: [
    {
      status: true,
      title: "Pre and post launch mobile app marketing pitfalls to avoid",
      text:
        "There are many variations of passages of available but majority have alteration in some by inject humour or random words. Lorem ipsum dolor sit amet, error insolens reprimique no quo, ea pri verterem phaedr vel ea iisque aliquam."
    },
    {
      status: false,
      title: "Boostup your application traffic is just a step away",
      text:
        "There are many variations of passages of available but majority have alteration in some by inject humour or random words. Lorem ipsum dolor sit amet, error insolens reprimique no quo, ea pri verterem phaedr vel ea iisque aliquam."
    },
    {
      status: false,
      title: "How to update application new features",
      text:
        "There are many variations of passages of available but majority have alteration in some by inject humour or random words. Lorem ipsum dolor sit amet, error insolens reprimique no quo, ea pri verterem phaedr vel ea iisque aliquam."
    },
    {
      status: false,
      title: "How to connect with the support to improve app experience",
      text:
        "There are many variations of passages of available but majority have alteration in some by inject humour or random words. Lorem ipsum dolor sit amet, error insolens reprimique no quo, ea pri verterem phaedr vel ea iisque aliquam."
    }
  ]
};

import blog1 from "@images/blog/blog-2-1.jpg";
import blog2 from "@images/blog/blog-2-2.jpg";
import blog3 from "@images/blog/blog-2-3.jpg";
import blog4 from "@images/blog/blog-2-4.jpg";
import blog5 from "@images/blog/blog-2-5.jpg";
import blog6 from "@images/blog/blog-2-6.jpg";

export const BLOG_DATA = {
  sectionTitle: {
    text: "From the Blog",
    title: "News & Articles"
  },
  posts: [
    {
      image: blog1,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Leverage agile frame works to",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    },
    {
      image: blog2,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Launch New Mobile Marketing Pitfalls",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    },
    {
      image: blog3,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Bring to the table win-win survival",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    },
    {
      image: blog4,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Capitalize on low hanging fruit to",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    },
    {
      image: blog5,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Collaboratively admin istrate via ",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    },
    {
      image: blog6,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Efficiently unleash cross-media ",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    }
  ]
};
